import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout'
import SEO from '../components/seo'
import OfflineFirstImage from "../components/OfflineFirstImage";
import {Pricing} from "../components/pricing";

const OfflineFirst = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Ordination"
                 googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
                 fbUrl="https://medoboard.com/offlineFirst"
                 fbImage="https://medoboard.com/public/medoboard-fb.png"
                 fbTitle="Medoboard - arbeitet offline First."
                 fbDescription="Medoboard arbeitet offline First: Lesen sie alle Vorteile von diesem Konzept"
                 keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
            
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <OfflineFirstImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Langsames Internet?<br/>Kein Problem!</h2>
                    <h4 className="text-muted mb-3">Medoboard wurde auf Performance optimiert</h4>
                    <p className="lead mb-4">
                        Medoboard arbeitet komplett offline. Das heisst dass Medoboard zum Laden großer Datenmengen oder
                        umfangreicher Patientendaten
                        keine Internetverbindung benötigt.
                    </p>
                    <p>
                        In unseren umfangreichen Gesprächen mit Ärzten haben wir die Geschwindigkeit der
                        Ordinationssoftware als zentrale Anforderung erkannt.
                        Patientendaten müssen schnell und zuverlässig mit einem Tastendruck verfügbar sein - in jeder
                        Situation. Ein Internetausfall, langsame
                        Verbindungsgeschwindigkeit oder andere Faktoren dürfen keinen Einfluss auf die tägliche Arbeit
                        mit den Patienten haben.
                    </p>
                    <p>
                        Und genau dafür hat Medoboard ein innovatives <i>offline first</i> Konzept entwickelt:
                    </p>
                    <ul>
                        <li><b>Medoboard arbeitet mit einer lokalen Datenbank</b><br/> Die Performance der Anwendung
                            entspricht der einer MS Access basierten Lösung
                            oder vergleichbaren Anwendungen
                        </li>
                        <li><b>Medoboard arbeitet komplett offline</b><br/> Die Abfragen werden immer gegen die
                            lokale Datenbank ausgeführt.
                        </li>
                        <li><b>Medoboard synchronisiert im Hintergrund</b><br/> Ohne jegliche Beeinträchtigung
                            synchronisiert Medoboard die Datenbank im Hintergrund.
                        </li>
                    </ul>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
)

export default OfflineFirst;